export enum ColumnTitle {
  accountId = 'Account-ID',
  activeAt = 'Active At',
  addressId = 'ID',
  addScope = 'Add Scope',
  after = 'Value after Change',
  apiKeyId = 'API Key ID',
  before = 'Value before Change',
  ble_mac_address = 'BLE-Address',
  bluetoothMacAddress = 'Bluetooth Mac Address',
  boxContractOffer = 'Box Contract Offer',
  boxFirmwareVersion = 'Firmware Version',
  firmware_version = 'FW',
  boxId = 'Product-ID',
  boxOfferId = 'Box Offer ID',
  brokerId = 'Broker-ID',
  carrierCode = 'Carrier',
  carrierDeliveryId = 'Delivery-ID',
  carrierTrackingCode = 'Carrier Reference Number',
  changelogItemId = 'Account / Contribution / Contract ID',
  changelogItemType = 'Tab Changed',
  city = 'City',
  code = 'Invite Code',
  codeId = 'Code-ID',
  company = 'Company',
  connection = 'Connection',
  consumedAt = 'Consumed At',
  contractId = 'Contract-ID',
  contractState = 'Contract State',
  contract_state = 'State',
  contributionId = 'Contribution-ID',
  countryCode = 'Country',
  createdAt = 'Created At',
  created_at = 'Created At',
  createdBy = 'Created by',
  createdByID = 'Change made by',
  createdByType = 'Role',
  creatorId = 'Creator-ID',
  date = 'Created At',
  deactivatedAt = 'Deactivated at',
  delete = 'Delete',
  description = 'Description',
  displayName = 'Display Name',
  durationInMonth = 'Duration (in months)',
  rentServicePercentage = '% of rent',
  email = 'Email',
  emailOrName = 'Email / Name',
  expectedLevel = 'Expected Level',
  expiresAfter = 'Expires After',
  fieldName = 'Specific Field Changed',
  firmwareVersion = 'Firmware version',
  firstName = 'First Name',
  firstTrancheInEuro = 'First Tranche',
  hardVersion = 'Hardware-Version',
  houseNumber = 'House Number',
  identifier = 'Box-Identifier',
  identityId = 'Identity-ID',
  imei = 'IMEI',
  immediateProvisionInEuro = 'Immediate Provision',
  incentiveType = 'Incentive Type',
  invitationType = 'Invitation Type',
  inviteCode = 'Invited by Code',
  isBillable = 'Billing status',
  isPrimary = 'Primary',
  isRevoked = 'Validity',
  isSocial = 'Social',
  issuerUserId = 'Issuer-ID',
  label = 'Label',
  lastName = 'Last Name',
  mecaVersion = 'Meca-Version',
  mechanicalConfiguration = 'Mechanical Configuration',
  mechanical_configuration = 'Mech',
  name = 'Name',
  notes = 'Note',
  numberOfContributions = '# of Contributions',
  numOfBox = 'Number of boxes',
  objectId = 'Object-ID',
  objectType = 'Object Type',
  offerId = 'Offer-ID',
  offerType = 'Offer Type',
  orderNumber = 'Order No',
  parsedActiveSince = 'Active Since',
  parsedCreatedAt = 'Created At',
  parsedDateBox = 'Created',
  parsedDateShipment = 'Created',
  parsedEmailsField = 'Recipient Emails',
  parsedManufacturingDate = 'Manufacturing Date',
  parsedMultiplier = 'Multiplier',
  parsedPaymentDate = 'Payment Date',
  parsedTimestamp = 'Timestamp',
  parsedUpdatedAt = 'Updated At',
  partnerId = 'Partner-ID',
  holder = 'Holder',
  holder_name = 'Holder name',
  holder_email = 'Holder email',
  partnerInChargeId = 'Partner in charge',
  partnerName = 'Partner Name',
  paymentDate = 'Payment Date',
  paymentMethod = 'Payment Method',
  paymentStatus = 'Payment Status',
  paymentUniqueId = 'Payment Unique ID',
  phoneNumber = 'Phone Number',
  position = 'Position',
  postalCode = 'Zipcode',
  productDescription = 'Article Description',
  productLifecycle = 'Lifecycle',
  lifecycle_status = 'Lifecycle',
  provider = 'Provider',
  providerUserId = 'Provider User ID',
  publicHandle = 'Public Handle',
  qualityChecklistId = 'Quality Checklist ID',
  qualityCheckTypeId = 'Quality Check Type ID',
  recipientAndSender = 'Recipient & Sender',
  reference = 'Shipment name',
  referenceId = 'Reference-ID',
  relationId = 'Relation-ID',
  relationType = 'Relation Type',
  roleId = 'Role-ID',
  roleName = 'Role Name',
  role = 'Role',
  scopeId = 'Scope-ID',
  scopes = 'Scopes',
  serviceId = 'Service-ID',
  serviceLevel = 'Service Level',
  services = 'Services',
  serviceType = 'Service Type',
  shipmentId = 'Shipment-ID',
  shipmentType = 'Shipment Type',
  shopId = 'Shop-ID',
  shop_name = 'Shop name',
  softVersion = 'Software-Version',
  source = 'Source',
  state = 'State',
  status = 'Status',
  street = 'Address',
  street2 = 'Additional Address',
  thingName = 'LPUI',
  tosId = 'ToS-ID',
  tosLocation = 'ToS Location',
  totalAmountInCents = 'Total Amount',
  totalPriceInEuro = 'Total Price',
  totalProvisionInEuro = 'Total Provision',
  trackingCode = 'Delivery key ',
  trackingCodeId = 'Delivery Key',
  type = 'Type',
  types = 'Types',
  updatedAt = 'Updated At',
  updated_at = 'Updated At',
  updatedBy = 'Updated by',
  website = 'Website',
  zone = 'Zone',
  iconImageUrl = 'Icon image URL',
  url = 'Title Link',
  title = 'Title Text',
  subtitleText = 'Sub-Title Text',
  publication_date = 'Date of Event',
  newsletter_number = 'Newsletter Number',
  subtitle = 'Subtitle',
  url_icon = 'Icon Image URl',
  orders = 'Order',
  lp_ui = 'LPUI',
  contract_state_updated_at = 'Since',
  battery_level = '%',
  lock_state = 'Lock state',
  partner_since = 'Assignment',
  sim_status = 'Sim Status',
  sim_status_updated_at = 'Last update SimStatus',
  addressTypes = 'Address Types',
  building = 'App, suite, building',
  clientId = 'Client-ID',
}

const ColumnIdKey = [
  'accountId',
  'contributionId',
  'partnerId',
  'offerId',
  'shopId',
  'roleId',
  'scopeId',
  'addressId',
  'referenceId',
  'reference',
  'boxId',
  'objectId',
  'relationId',
  'shipmentId',
  'identifier',
  'carrierDeliveryId',
  'paymentUniqueId',
  'creatorId',
  'codeId',
  'issuerUserId',
  'identityId',
  'providerUserId',
  'orderNumber',
  'trackingCode',
  'trackingCodeId',
  'carrierTrackingCode',
  'imei',
  'serviceId',
  'contractId',
  'brokerId',
  'boxOfferId',
  'tosId',
  'qualityCheckTypeId',
  'qualityChecklistId',
  'apiKeyId',
  'partnerInChargeId',
  'role',
  'source',
  'clientId',
];

export const isId = (col) => (ColumnIdKey.includes(col) ? col : '');

import React, { useCallback, useContext } from 'react';

import { Grid, Stack } from '@lp/ds-next';
import { Tag, Typography, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';

import PendingV2Icon from '../../../../components/icons/PendingV2Icon';
import ValidatedIcon from '../../../../components/icons/Validated';
import CreatePartner from '../components/CreatePartner';
import ImportCSVFile from '../components/ImportCSVFile';
import { LPPartnerFilterForm } from '../components/LPParnterFilterForm';
import useGetPartners from '../hooks/useGetPartners';
import useLPPartnersStore, {
  TLPPartnersSortBy,
} from '../hooks/useLPPartnersStore';
import BlockedIcon from '@/components/icons/BlockedIcon';
import Refresh from '@/components/icons/Refresh';
import StyledTable from '@/components/tables/StyledTable';
import TablesTotals from '@/components/TablesTotals';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';
import { VillageTotalContext } from '@/shared/providers/villageTotalProvider';
import { capitalizeFirstLetter } from '@/utils/format';

//Create constant object with statusIcon
const statusIcon = {
  VALIDATED: <ValidatedIcon />,
  PENDING: <PendingV2Icon />,
  BLOCKED: <BlockedIcon />,
} as const;

const { Text } = Typography;

const LPPartnersTable = () => {
  useGetPartners();
  const rowData = useLPPartnersStore((state) => state.rowData);
  const loading = useLPPartnersStore((state) => state.loading);
  const pageSize = useLPPartnersStore((state) => state.pageSize);
  const offset = useLPPartnersStore((state) => state.offset);
  const total = useLPPartnersStore((state) => state.total);
  const defaultSort = useLPPartnersStore((state) => state.defaultSort);
  const dispatch = useLPPartnersStore((state) => state.dispatch);
  const windowSize = useWindowSize();
  const vTotal = useContext(VillageTotalContext);

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'clientId',
      'name',
      'updatedAt',
      'villageServiceLevel',
      'businessType',
      'isInternal',
      'businessStatus',
      'PartnerIDCard',
    ],
    defaultSort: defaultSort,
    eyeLinkProps: { to: '/lp-village/partners', width: '1.5rem' },
    columnsSpecialProps: {
      clientId: {
        width: '4.375rem',
      },
      name: {
        width: '5rem',
      },
      updatedAt: {
        width: '4.375rem',
      },
      villageServiceLevel: {
        width: '3.75rem',
      },
      isInternal: {
        title: 'Partner profile',
        width: '2.5rem',
        render: (_text, record) =>
          record.isInternal ? 'Internal' : 'External',
      },
      businessType: {
        title: 'Account type',
        width: '2.5rem',
        render: (_text, record) =>
          capitalizeFirstLetter(record.businessInformation.type),
      },
      businessStatus: {
        title: 'Account status',
        width: '2.5rem',
        render: (_text, record) => (
          <>
            <Stack flexDirection="row" gap="0.2rem" alignItems="center">
              {statusIcon[record.businessInformation.status]}

              {capitalizeFirstLetter(record.businessInformation.status)}
            </Stack>
          </>
        ),
      },
      PartnerIDCard: {
        title: 'Partner ID Card',
        render: (_text, record) => (
          <Space>
            <Tag
              color={record.isPartnerIDCardCompleted ? '  #E9F5F1' : '#F4F4F4'}
              style={{
                fontSize: '0.75rem',
                fontWeight: 400,
              }}
            >
              <Text
                strong
                style={{
                  color: record.isPartnerIDCardCompleted
                    ? '  #0CA86D'
                    : '#3F3F3F',
                }}
              >
                {record.isPartnerIDCardCompleted
                  ? 'Completed'
                  : 'Not completed'}
              </Text>
            </Tag>

            {!record.isERPSyncOK && (
              <Tag
                style={{ display: 'flex', alignItems: 'center' }}
                color="red"
              >
                <Refresh style={{ height: '20px' }} />
              </Tag>
            )}
          </Space>
        ),
      },
    },
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const handleTableChange = useCallback(
    (pagination, _filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TLPPartnersSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid container justifyContent="center" px="5rem">
      <Grid item mobile={12}>
        <LPPartnerFilterForm dispatch={dispatch} />
      </Grid>
      <Grid item mobile={12}>
        <Grid container gap={2}>
          <Grid item container>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
            >
              <Grid item>
                <ImportCSVFile
                  buttonText="Import Late Shops CSV"
                  modalTitle="Import List of Late Shops"
                />
              </Grid>
              <Grid item>
                <CreatePartner />
              </Grid>
            </Grid>
          </Grid>
          <Grid item mobile={12}>
            <StyledTable
              rowKey="id"
              dataSource={rowData}
              columns={columns}
              size="small"
              pagination={pagination}
              loading={loading}
              scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
              onChange={handleTableChange}
              footer={() => <TablesTotals total={total} gTotal={vTotal} />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LPPartnersTable;

import React, { useContext, useMemo } from 'react';

import { Stack } from '@chakra-ui/react';
import {
  Text,
  Divider,
  Grid,
  NewTextField as TextField,
  Select,
  NewTooltip as Tooltip,
  ButtonV2,
  RefreshIcon,
} from '@lp/ds-next';
import { Card, CardContent } from '@mui/material';
import { useFormikContext } from 'formik';

import { useSyncWithERP } from '../../api/syncWithERP';
import { PartnerData } from '../../api/useLPPartner';
import {
  IPartnerV3,
  PARTNER_BUSINESS_STATUS,
  PARTNER_BUSINESS_TYPE,
} from '../../models/partnerV3';
import { CurrentPartnerContext } from '../../views/LPPartnerDetails';
import { COUNTRY_CODES_JSON_PATH } from '@/config';
import { formatStringToDate } from '@/helpers/dates';
import useCountryCodes from '@/shared/hooks/useCountryCodes';

interface PartnerIDcardProps {
  erpSynchronizedAt?: string;
}

const PartnerIdCard = ({ erpSynchronizedAt }: PartnerIDcardProps) => {
  const { partnerState: partner } = useContext<PartnerData>(
    CurrentPartnerContext
  );

  const canSyncWithERP =
    partner.data.business_information.type === PARTNER_BUSINESS_TYPE.PRO &&
    partner.data.business_information.status ===
      PARTNER_BUSINESS_STATUS.VALIDATED;

  const [countryCodes, getCountryNameFromCode] = useCountryCodes({
    localCountryCodesPath: COUNTRY_CODES_JSON_PATH,
  });

  const { mutate: syncWithERP, isPending: syncInProgress } = useSyncWithERP();

  const { handleChange, handleBlur, values, errors, touched } =
    useFormikContext<IPartnerV3>();

  const countries = useMemo(
    () =>
      countryCodes?.data?.map((option) => ({
        label: `${getCountryNameFromCode(option.alpha2Code)} (${option.alpha2Code}) `,
        value: option.alpha2Code,
      })),
    [countryCodes, getCountryNameFromCode]
  );

  const handleSycnWithERPClick = async () => {
    await syncWithERP();
  };

  return (
    <Card elevation={0} sx={{ borderRadius: '10px' }}>
      <CardContent>
        <Grid container direction="column">
          {/* ----- Header ----- */}
          <Grid item mobile={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text flex={1} variant="titleL" component="div">
                Partner ID Card
              </Text>
              <Text
                sx={{ color: 'custom.neutral.black.20' }}
                variant="titleS"
                component="div"
              >
                Last sync with ERP :{' '}
                {erpSynchronizedAt
                  ? formatStringToDate(erpSynchronizedAt)
                  : '-'}
              </Text>

              <Tooltip
                title={
                  <Text variant="bodyTextL">
                    {canSyncWithERP
                      ? 'Click to synchronize partner data to the ERP'
                      : 'You need a Pro AND Validated account to sync with the ERP'}
                  </Text>
                }
              >
                <ButtonV2
                  data-testid="sync-with-erp-button"
                  size="small"
                  variant="flat"
                  icon={RefreshIcon}
                  disabled={syncInProgress || !canSyncWithERP}
                  onClick={handleSycnWithERPClick}
                />
              </Tooltip>
            </Stack>
            <Divider sx={{ mt: '.625rem', mb: '1.25rem' }} />
          </Grid>
          {/* ----- Main Information ----- */}
          <Grid item mobile={12} container direction="row" spacing={1}>
            <Grid item mobile={12} tablet={6}>
              <TextField
                onChange={handleChange}
                label="Client ID"
                placeholder="CUSXXXXXX"
                name="partner_id_card.client_id"
                value={values.partner_id_card.client_id}
                disabled
                data-testid="partner-client-id"
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <TextField
                required
                onChange={handleChange}
                label="Name"
                name="partner_id_card.client_name"
                value={values.partner_id_card.client_name}
                error={
                  touched.partner_id_card?.client_name &&
                  !!errors.partner_id_card?.client_name
                }
                helperText={errors.partner_id_card?.client_name}
                onBlur={handleBlur}
                data-testid="partner-client-name"
              />
            </Grid>
            <Grid item mobile={12} tablet={12}>
              <Select
                label="Country code"
                options={countries}
                {...{
                  onChange: handleChange,
                  name: 'partner_id_card.country_code',
                  required: true,
                  helperText: errors.partner_id_card?.country_code,
                }}
                error={
                  touched?.partner_id_card?.country_code &&
                  !!errors?.partner_id_card?.country_code
                }
                onBlur={handleBlur}
                value={values.partner_id_card.country_code}
                data-testid="partner-country-code"
              />
            </Grid>

            <Grid mobile={12} />
            <Grid item mobile={12} tablet={6}>
              <TextField
                onChange={handleChange}
                label={
                  values.partner_id_card.country_code === 'FR'
                    ? 'SIREN'
                    : 'Company ID'
                }
                name="partner_id_card.company_id"
                value={values.partner_id_card.company_id}
                error={!!errors.partner_id_card?.company_id}
                helperText={errors.partner_id_card?.company_id}
                data-testid="partner-company-id"
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <TextField
                onChange={handleChange}
                label="VAT Number"
                name="partner_id_card.vat_number"
                value={values.partner_id_card.vat_number}
                error={!!errors.partner_id_card?.vat_number}
                helperText={errors.partner_id_card?.vat_number}
                data-testid="partner-vat-number"
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                onChange={handleChange}
                label="Internal sales name"
                name="partner_id_card.internal_sales_name"
                value={values.partner_id_card.internal_sales_name}
                error={!!errors.partner_id_card?.internal_sales_name}
                helperText={errors.partner_id_card?.internal_sales_name}
                data-testid="partner-internal-sales-name"
              />
            </Grid>
          </Grid>
          {/* ----- CONTACT ----- */}
          <Grid
            item
            mobile={12}
            container
            direction="row"
            spacing={1}
            mt="1rem"
          >
            <Grid item mobile={12}>
              <Text variant="titleS" component="div">
                Contact
              </Text>
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <TextField
                onChange={handleChange}
                label="Email"
                name="partner_id_card.email"
                value={values.partner_id_card.email}
                error={!!errors.partner_id_card?.email}
                helperText={errors.partner_id_card?.email}
                data-testid="partner-email"
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <TextField
                onChange={handleChange}
                label="Website"
                name="partner_id_card.website"
                value={values.partner_id_card.website}
                error={!!errors.partner_id_card?.website}
                helperText={errors.partner_id_card?.website}
                data-testid="partner-website"
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PartnerIdCard;

export const capitalizeFirstLetter = (str) =>
  str
    .split('')
    .map((letter: string, i: number) =>
      i === 0 ? letter.toUpperCase() : letter.toLowerCase()
    )
    .join('');

export const trimSearchTerms = (searchTerms: {}) => {
  const result = {};

  if (!searchTerms) {
    return result;
  }

  Object.entries(searchTerms).forEach(([key, value]) => {
    if (typeof value === 'string') {
      result[key] = value.trim();
    } else {
      result[key] = value;
    }
  });

  return result;
};

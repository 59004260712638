import React, { useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Select,
  Checkbox,
  Space,
  Tag,
  Typography,
  Row,
  Col,
  Input,
} from 'antd';

import {
  PARTNER_STATUS,
  PARTNER_TYPES,
  PARTNER_PROFILE,
  PARTNER_ERP_SYNCHRONISATION_STATE,
  PARTNER_ACCOUNT_STATUS,
} from '../types/partner';
import { capitalizeFirstLetter, trimSearchTerms } from '@/utils/format';

const { Text } = Typography;
const SEARCH_COL_SIZE = 3;

const partnerTypeOptions = Object.values(PARTNER_TYPES).map((type) => ({
  value: type,
  label: capitalizeFirstLetter(type),
}));

const partnerStatusOptions = Object.values(PARTNER_STATUS).map((status) => ({
  value: status,
  label: capitalizeFirstLetter(status),
}));

const partnerProfileOptions = Object.values(PARTNER_PROFILE).map((profile) => ({
  value: profile === PARTNER_PROFILE.INTERNAL,
  label: capitalizeFirstLetter(profile),
}));

const partnerAccountStatusOptions = Object.values(PARTNER_ACCOUNT_STATUS).map(
  (status) => ({
    value: status === PARTNER_ACCOUNT_STATUS.COMPLETED,
    label: capitalizeFirstLetter(status),
  })
);

const partnerERPSyncOptions = Object.values(
  PARTNER_ERP_SYNCHRONISATION_STATE
).map((status) => ({
  value: status === PARTNER_ERP_SYNCHRONISATION_STATE.SYNCHRONISED,
  label: capitalizeFirstLetter(status.replace('_', ' ')),
}));

export const LPPartnerFilterForm = ({ dispatch }) => {
  const [searchTerms, setSearchTerms] = useState<{ [key: string]: string }>({
    businessType: PARTNER_TYPES.PRO,
  });
  const [statusSelected, setStatusSelected] = useState<any>([]);

  const handleChange = (event) => {
    setSearchTerms({
      ...searchTerms,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const trimmedTerms = trimSearchTerms(searchTerms);

    dispatch({ type: 'updateSearchTerms', args: trimmedTerms });
  };

  const handleCheckboxChange = (value) => {
    const newSelectedValue = statusSelected.includes(value)
      ? statusSelected.filter((item) => item !== value)
      : [...statusSelected, value];

    setStatusSelected(newSelectedValue);
    setSearchTerms({
      ...searchTerms,
      businessStatuses: newSelectedValue.join(','),
    });
  };

  const handleReset = () => {
    setStatusSelected([]);
    setSearchTerms({ businessType: 'PRO' });
    dispatch({
      type: 'updateSearchTerms',
      args: { businessType: PARTNER_TYPES.PRO },
    });
  };

  return (
    <>
      <Row>
        <Col
          style={{
            marginBottom: '.75rem',
          }}
        >
          <Text
            style={{
              fontSize: '1rem',
              fontWeight: '500',
            }}
          >
            Search by:
          </Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8}>
          <Input
            name="name"
            placeholder="Name"
            onChange={handleChange}
            value={searchTerms['name']}
          />
        </Col>
        <Col md={8}>
          <Input
            name="clientId"
            placeholder="Client-ID"
            onChange={handleChange}
            value={searchTerms['clientId']}
          />
        </Col>
        <Col md={4}>
          <Button
            type="primary"
            shape="round"
            icon={<SearchOutlined />}
            onClick={handleSubmit}
            data-testid="partner-filter-search-button"
          >
            Search
          </Button>
        </Col>
      </Row>
      <Row gutter={16} style={{ paddingTop: '24px', paddingBottom: '24px' }}>
        <Col md={SEARCH_COL_SIZE}>
          <Select
            placeholder="Account type"
            options={partnerTypeOptions}
            value={searchTerms.businessType}
            defaultValue={searchTerms.businessType}
            onChange={(value) => {
              setSearchTerms({ ...searchTerms, businessType: value });
            }}
            style={{ width: '100%' }}
            data-testid="partner-filter-form-type"
          />
        </Col>
        <Col md={SEARCH_COL_SIZE}>
          <Select
            allowClear
            placeholder="Partner profile"
            options={partnerProfileOptions}
            value={searchTerms.isInternal}
            onChange={(value) => {
              setSearchTerms({ ...searchTerms, isInternal: value });
            }}
            style={{ width: '100%' }}
            data-testid="partner-filter-form-profile"
          />
        </Col>
        <Col md={SEARCH_COL_SIZE + 1}>
          <Select
            data-testid="partner-filter-form-status"
            placeholder="Account status"
            mode="tags"
            maxTagCount="responsive"
            value={statusSelected}
            style={{ width: '100%' }}
            tagRender={(props) => {
              const { label } = props;

              return (
                <>
                  <Tag
                    color="default"
                    closable
                    onClose={() => {
                      handleCheckboxChange(label);
                    }}
                  >
                    {capitalizeFirstLetter(label)}
                  </Tag>
                </>
              );
            }}
            dropdownRender={() => (
              <Space direction="vertical" align="start">
                {partnerStatusOptions.map(({ label, value }) => (
                  <Space
                    key={value}
                    direction="horizontal"
                    align="center"
                    style={{ paddingLeft: '.75rem' }}
                  >
                    <Checkbox
                      onChange={() => handleCheckboxChange(value)}
                      checked={statusSelected.includes(value)}
                      data-testid={`partner-filter-form-status-${value}`}
                    >
                      {capitalizeFirstLetter(label)}
                    </Checkbox>
                  </Space>
                ))}
              </Space>
            )}
          />
        </Col>
        <Col md={SEARCH_COL_SIZE}>
          <Select
            allowClear
            data-testid="partner-filter-form-state"
            placeholder="Partner ID card"
            options={partnerAccountStatusOptions}
            value={searchTerms.isPartnerIDCardCompleted}
            onChange={(value) => {
              setSearchTerms({
                ...searchTerms,
                isPartnerIDCardCompleted: value,
              });
            }}
            style={{ width: '100%' }}
          />
        </Col>
        <Col md={SEARCH_COL_SIZE + 1}>
          <Select
            allowClear
            data-testid="partner-filter-form-syncERP"
            placeholder="ERP synchronisation"
            options={partnerERPSyncOptions}
            value={searchTerms.isERPSyncOK}
            onChange={(value) => {
              setSearchTerms({ ...searchTerms, isERPSyncOK: value });
            }}
            style={{ width: '100%' }}
          />
        </Col>
        <Col>
          <Button
            shape="round"
            icon={<CloseOutlined />}
            onClick={handleReset}
            data-testid="partner-filter-reset-button"
          >
            Reset all Filters
          </Button>
        </Col>
      </Row>
    </>
  );
};
